import { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './WomensFashion.css';
import { Link } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
export class WomensFashion extends Component {
    render() {
        return (
            <>
                <div className='container-fluid'>
                    {/* Start Of SideBar Row */}
                    <div className='row'>
                        {/* End Of Side Bar */}
                        {/* min-vh-100 */}
                        <div className='col-md-2 col-sm-2 nav-bg'>
                            <div className='d-flex justify-content-between flex-column'>
                                <div>
                                    {/* d-none d-sm-inline */}
                                    <a className='text-decoration-none text-dark  align-itemcenter'>
                                        <span className='ms-1 fs-4'>Women's Fashion</span>
                                    </a>
                                    <hr className='text-secondary'></hr>
                                    <ul className='nav nav-pills flex-column'>
                                        <li className='nav-item text-white fs-5  py-2 py-sm-0'>
                                            <Link to="/top-brand" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-person-standing-dress'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>DashBoard</span> */}
                                                <span className='ms-1'>Top Brands</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/innerwear" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-person-standing-dress'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>Innerwear</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/t-shirts" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-person-standing-dress'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>T-shirts</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/shirts" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-person-standing-dress'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>Shirts</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/jeans" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-person-standing-dress'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>Jeans</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/hoodies-and-jakets" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-person-standing-dress'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'> Hoodies and Jakets</span>
                                            </Link>
                                        </li>
                                        <hr className='text-secondary'></hr>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* End Of Side Bar */}
                        {/* Call Here */}
                        {/* Adding New Laptop this Page */}
                        {/* <ShowAllLaptop></ShowAllLaptop> */}
                        <div className='col-md-3 col-sm-3 mt-3'>
                            <Card >
                                <a href='https://amzn.to/41l8eY1' target="_blank">
                                    <Card.Img variant="top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" />

                                </a>
                                <Card.Body>
                                    <Card.Title>Aspire Lite</Card.Title>
                                    <Card.Text>
                                        Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>8GB RAM/512GB SSD/Windows 11 Home</ListGroup.Item>
                                    <ListGroup.Item>AL15-52, 39.62cm (15.6") Full HD Display</ListGroup.Item>
                                    <ListGroup.Item>Metal Body, Steel Gray, 1.59 Kg</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <a href='https://amzn.to/41l8eY1' className='btn btn-primary me-4' target='_blank'>Buy Now</a>
                                    <a href='https://amzn.to/41l8eY1' className='btn btn-danger' target='_blank'>Buy Now</a>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-3 col-sm-3 mt-3'>
                            <Card >
                                <a href='https://amzn.to/41l8eY1' target="_blank">
                                    <Card.Img variant="top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" />

                                </a>
                                <Card.Body>
                                    <Card.Title>Aspire Lite</Card.Title>
                                    <Card.Text>
                                        Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>8GB RAM/512GB SSD/Windows 11 Home</ListGroup.Item>
                                    <ListGroup.Item>AL15-52, 39.62cm (15.6") Full HD Display</ListGroup.Item>
                                    <ListGroup.Item>Metal Body, Steel Gray, 1.59 Kg</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <a href='https://amzn.to/41l8eY1' className='btn btn-primary me-4' target='_blank'>Buy Now</a>
                                    <a href='https://amzn.to/41l8eY1' className='btn btn-danger' target='_blank'>Buy Now</a>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='col-md-3 col-sm-3 mt-3'>
                            <Card >
                                <a href='https://amzn.to/41l8eY1' target="_blank">
                                    <Card.Img variant="top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" />

                                </a>
                                <Card.Body>
                                    <Card.Title>Aspire Lite</Card.Title>
                                    <Card.Text>
                                        Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>8GB RAM/512GB SSD/Windows 11 Home</ListGroup.Item>
                                    <ListGroup.Item>AL15-52, 39.62cm (15.6") Full HD Display</ListGroup.Item>
                                    <ListGroup.Item>Metal Body, Steel Gray, 1.59 Kg</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <a href='https://amzn.to/41l8eY1' className='btn btn-primary me-4' target='_blank'>Buy Now</a>
                                    <a href='https://amzn.to/41l8eY1' className='btn btn-danger' target='_blank'>Buy Now</a>
                                </Card.Body>
                            </Card>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}