// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Laptop from './Pages/Laptop/Laptop';
import { Computer } from './Pages/Computer/Computer';
import { Mobile } from './Pages/Mobile/Mobile';
import { MensFashion } from './Pages/MensFashion/MensFashion';
import { Sound } from './Pages/Sound/Sound';
import { Watch } from './Pages/Watch/Watch';
import LenovoLaptop from './Pages/Laptop/LenovoLaptop';
import SideBar from './Components/SideBar';
import Dash from './Dash';
import { PrivacyPolicy } from './Pages/Policy/PrivacyPolicy';
import { WomensFashion } from './Pages/WomensFashion/WomensFashion';
import Database from './Demo/Database';
import ShowData from './Demo/ShowData';
import DemoDash from './Demo/DemoDash';
import RegAdmin from './Admin/Admin/RegAdmin';
import EditAdmin from './Admin/Admin/EditAdmin';
import AddSliderImg from './Admin/Slider/AddSliderImg';
import UpdateSlider from './Admin/Slider/UpdateSlider';
import AddTretrendingItem from './Admin/TrendingItem/AddtTendingItem';
import AddLaptopCategory from './Admin/Laptop/LaptopCategory/AddLaptopCategory';
import ShowLaptopcategory from './Admin/Laptop/LaptopCategory/ShowAllLaptopCategory';
import EditLaptopCategory from './Admin/Laptop/LaptopCategory/EditLaptopCategory';
import AdminLogin from './Admin/AdminLogin';
import AddLaptop from './Admin/Laptop/LaptopProduct/AddLaptop';
import ShowAllLaptop from './Admin/Laptop/LaptopProduct/ShowAllLaptop';
import ShowAllAdmin from './Admin/Admin/ShowAllAdmin';
import { CookiesProvider } from "react-cookie";
import NewAdmin from './Admin/NewAdmin';

function App() {
  return (

    //  <DemoDash></DemoDash>
    <BrowserRouter>
      <CookiesProvider>
        <SideBar>
          <Routes>
            <Route exact index element={<Dash />}></Route>
            `<Route path='/laptop' element={<Laptop />}></Route>
            <Route path='/top-10-laptop' element={<Laptop />}></Route>
            <Route path='/computer' element={<Computer />}></Route>
            <Route path='/mobile' element={<Mobile />}></Route>
            {/* <Route path='/fashion' element={<Fashion />}></Route> */}
            <Route path='/sound' element={<Sound />}></Route>
            <Route path='/watch' element={<Watch />}></Route>
            <Route path='/lenovolaptop' element={<LenovoLaptop />}></Route>
            <Route path='/privacy-policy' element={<PrivacyPolicy />}></Route>
            {/* Display mens-fashion Page */}
            <Route path='/mens-fashion' element={<MensFashion />}></Route>
            {/* Display For womens-fashion Page */}
            <Route path='/womens-fashion' element={<WomensFashion />}></Route>

            {/* Start Of Demo Routes */}
            <Route path='/Database' element={<Database />}></Route>
            <Route path='/showdata' element={<ShowData />}></Route>
            <Route path='/DemoDash' element={<DemoDash />}></Route>
            {/* End Of Demo Routes */}




            {/* Routes For Admin */}
            {/* <Route path='/login' element={<AdminLogin />}/> */}
            <Route path='/login-admin' element={<AdminLogin />} />
            <Route path='/reg-new-admin' element={<NewAdmin />} />
            <Route path='/new-admin' element={<RegAdmin />} />
            <Route path='/editadmin/:id' element={<EditAdmin />} />
            <Route path='/new-slider' element={<AddSliderImg />} />
            <Route path='/editSliderImg/:id' element={<UpdateSlider />} />
            <Route path='/show-all-admin' element={<ShowAllAdmin />} />

            {/* Adding For Trending Product */}
            <Route path='/add-trending-item' element={<AddTretrendingItem />} />
            {/* For Adding Laptop category */}
            <Route path='/add-laptop-category' element={<AddLaptopCategory />} />
            <Route path='/show-all-laptop-category' element={<ShowLaptopcategory />} />
            <Route path='/editlaptopcategory/:id' element={<EditLaptopCategory />} />

            {/* Route For Adding Laptop */}
            <Route path='/add-new-laptop' element={<AddLaptop />} />
            <Route path='/show-all-laptop' element={<ShowAllLaptop />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </SideBar>

      </CookiesProvider>



    </BrowserRouter>





  );

}

const NotFound = () => {
  return (
    <div>
      <h1>404: Not Found</h1>
      <p>The page you requested could not be found.</p>
    </div>
  );
};
export default App;
