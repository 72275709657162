import { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './Watch.css';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom'


export class Watch extends Component{
    render(){
        return(
            <>
            <div className='container-fluid'>
                    {/* Start Of SideBar Row */}
                    <div className='row'>
                        {/* End Of Side Bar */}
                        {/* min-vh-100 */}
                        <div className='col-md-3 col-sm-2 nav-bg'>
                            <div className='d-flex justify-content-between flex-column'>
                                <div>
                                    {/* d-none d-sm-inline */}
                                    <a className='text-decoration-none text-dark  align-itemcenter'>
                                        <span className='ms-1 fs-4'>Watch Menu</span>
                                    </a>
                                    <hr className='text-secondary'></hr>
                                    <ul className='nav nav-pills flex-column'>
                                        <li className='nav-item text-white fs-5  py-2 py-sm-0'>
                                            <Link to="/top-10-laptop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>DashBoard</span> */}
                                                <span className='ms-1'>Top 10 Laptop</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/lenovolaptop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>Lenovo Laptop</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/dell-laptop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>Dell Laptop</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/asus-laptop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>ASUS Laptop</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/hp-laptop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>HP Laptop</span>
                                            </Link>
                                        </li>
                                        <hr className='text-secondary'></hr>
                                        <li className='nav-item text-dark fs-5 py-sm-0'>
                                            <Link className='nav-link text-dark' aria-current='page'>
                                                <b> <i className='bi bi-currency-rupee'></i></b>
                                                <span className='ms-1'> <b>Select Price</b></span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/30k-40k" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-1'>30,000 - 45,000</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* End Of Side Bar */}
                        {/* Call Here */}
                        {/* Adding New Laptop this Page */}
                        <div className='col-md-3 col-sm-3 mt-3'>
                <Card >
                    <a href='https://amzn.to/41l8eY1' target="_blank">
                        <Card.Img variant="top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" />
                    </a>
                    <Card.Body>
                        <strong><span className="discount">-38%</span> <i className='bi bi-currency-rupee'>32,990</i>  </strong><br />
                        <del className="delTag">M.R.P. : ₹52,990</del><br />
                        <span className="emiText">EMI starts at ₹1,599. No Cost EMI available</span><br />
                        <Card.Title>Aspire Lite</Card.Title>
                        <Card.Text>
                            Acer Aspire Lite 12th Gen Intel Core i3-1215U Premium Metal Laptop
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>8GB RAM/512GB SSD/Windows 11 Home</ListGroup.Item>
                        <ListGroup.Item>AL15-52, 39.62cm (15.6") Full HD Display</ListGroup.Item>
                        <ListGroup.Item>Metal Body, Steel Gray, 1.59 Kg</ListGroup.Item>
                    </ListGroup>
                    <Card.Body>
                        <a href='https://amzn.to/41l8eY1' className='btn btn-primary me-4' target='_blank'>Buy Now</a>
                        <a href='https://amzn.to/41l8eY1' className='btn btn-danger' target='_blank'>Buy Now</a>
                    </Card.Body>
                </Card>
            </div>
                        
                    </div>
                </div>
            </>
        )
    }
}