import { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function NewAdmin() {
    // const BaseUrl = 'http://localhost:80/Sam_API/';
    const BaseUrl = 'https://amolkhotevibe.000webhostapp.com/Sam_API/';
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
      };
    const handleSubmit = (event) => {
        event.preventDefault();
        // Offline Databse URL
        axios.post(BaseUrl + "AddNewAdmin", inputs).then(function (response) {
            if(response.data.status == 1){
                alert(response.data.message);
                navigate('/login-admin');
            }else{
                alert(response.data.message);
            }
        });
      };
    
    return (
        <>
            <div className="container py-5 px-5">
            <div className="row py-5">
          <div className="col-md-2">
            {/* <Link as='Link' to='/show-all-admin' className='btn btn-outline-primary'>All Admin</Link> */}
          </div>
          <div className="col-md-8 card py-4 px-4">
            <h2 className="text-center">Add New Admin</h2>
            {/* <div className='col-md-6'> */}
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="mt-2">Name</label>
                <input type="text" autoComplete="off" className="form-control mt-2" onChange={handleChange} placeholder="Enter Name" name="name" />
              </div>
              <div className="form-group">
                <label className="mt-2">Username</label>
                <input type="text" autoComplete="off" className="form-control mt-2" onChange={handleChange} placeholder="Enter Username" name="username" />
              </div>
              <div className="form-group">
                <label className="mt-2">Email address</label>
                <input type="email" autoComplete="off" name="email" className="form-control mt-2" onChange={handleChange} placeholder="Enter email" />
              </div>
              <div className="form-group">
                <label className="mt-2">password</label>
                <input type="password" name="password" className="form-control mt-2" onChange={handleChange} placeholder="Enter Password" />
              </div>
              <button type="submit" className="btn btn-primary mt-3">
                Submit
              </button>
            </form>
          </div>
        </div>
            </div>
        </>
    );
}