import { Component, React } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './Computer.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
export class Computer extends Component {
    render() {
        return (
            <>
                <div className='container-fluid'>
                    {/* Start Of SideBar Row */}
                    <div className='row'>
                        {/* End Of Side Bar */}
                        {/* min-vh-100 */}
                        <div className='col-md-3 col-sm-3 nav-bg'>
                            <div className='d-flex justify-content-between flex-column'>
                                
                                    {/* d-none d-sm-inline */}
                                    <a className='text-decoration-none text-dark  align-itemcenter'>
                                        <span className='ms-1 fs-4'>Computer Menu</span>
                                    </a>
                                    <hr className='text-secondary'></hr>
                                    <ul className='nav nav-pills flex-column'>
                                        <li className='nav-item text-white fs-5  py-2 py-sm-0'>
                                            <Link to="/top-10-desktop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>DashBoard</span> */}
                                                <span className='ms-2'>Top 10 Desktop</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/dell-desktop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-2'>Dell Desktop</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/asus-desktop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-2'>ASUS Desktop</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/hp-desktop" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-2'>HP Desktop</span>
                                            </Link>
                                        </li>
                                        <hr className='text-secondary'></hr>
                                        <li className='nav-item text-dark fs-5 py-sm-0'>
                                            <Link className='nav-link text-dark' aria-current='page'>
                                                <b> <i className='bi bi-currency-rupee'></i></b>
                                                <span className='ms-2'> <b>Select Price</b></span>
                                            </Link>
                                        </li>
                                        <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                            <Link to="/30k-40k" className='nav-link text-dark' aria-current='page'>
                                                <i className='bi bi-laptop'></i>
                                                {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                                <span className='ms-2'>30,000 - 45,000</span>
                                            </Link>
                                        </li>
                                    </ul>
                                
                            </div>
                        </div>


                        <div className='col-auto col-md-4 col-sm-3 mt-3'>
                            <Card >
                                <a href="https://amzn.to/47VF3N5" target="_blank">
                                    <Card.Img variant="top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08N5XSG8Z&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" />
                                </a>
                                <Card.Body>
                                    <Card.Title>Apple MacBook Air</Card.Title>
                                    <Card.Text>
                                        Apple MacBook Air Laptop M1 chip Backlit Keyboard, FaceTime HD Camera, Touch ID. Gold
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>8GB RAM, 256GB SSD Storage</ListGroup.Item>
                                    <ListGroup.Item>13.3-inch/33.74 cm Retina Display</ListGroup.Item>
                                    <ListGroup.Item>All-Day Battery Life – Go longer than ever with up to 18 hours of battery life</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <a href='https://amzn.to/47VF3N5' className='btn btn-primary me-4' target='_blank'>More Info</a>
                                    <a href='https://amzn.to/47VF3N5' className='btn btn-danger' target='_blank'>Buy Now</a>
                                </Card.Body>
                            </Card>
                        </div>
                        {/* End Of Side Bar */}
                        {/* Call Here */}
                    </div>
                </div>
            </>
        )
    }
}


