import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useCookies } from 'react-cookie';

export default function EditLaptopCategory() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);


    const { id } = useParams();
    useEffect(() => {
        checkAdminLogin();
        getinput();
    }, []);


    // Function For Check Admin Is Loged In Or Not
    function checkAdminLogin() {
        const myCookieValue = readCookie('AdminId');

        if (!myCookieValue) {
            navigate('/login-admin')
        }
    }
    function getinput() {
        // Offline Database URL
        // axios.get(`http://localhost:80/Sam_API/getLaptopCat/${id}`).then(function (response) {
        //     setInputs(response.data);
        // });
        // ONLine Database URL
        axios.get(`https://amolkhotevibe.000webhostapp.com/Sam_API/getLaptopCat/${id}`).then(function (response) {
            setInputs(response.data);
        });
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        // OffLine Database URL
        // axios.post(`http://localhost:80/Sam_API/updateLapiCat/${id}`, inputs).then(function (response) {
        //     if(response.data.status == 1){
        //         alert(response.data.message);
        //         console.log(response.data);
        //         navigate('/add-laptop-category');
        //     }        
        // });
        // ONLine Database URL
        axios.post(`https://amolkhotevibe.000webhostapp.com/Sam_API/updateLapiCat/${id}`, inputs).then(function (response) {
            if (response.data.status == 1) {
                alert(response.data.message);
                // console.log(response.data);
                navigate('/add-laptop-category');
            }else{
                alert(response.data.message);
                // console.log(response.data);
            }
        });
    }
    const [cookies, setCookie, removeCookie] = useCookies();

    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };
    return (
        <>
            <div className="container py-5 px-5">
                <div className='row'>
                    <div className='col-md-8'>
                        <Navbar expand="lg" >
                            <Navbar.Brand href="#" className='py-2 pl-2'><b>Admin Links</b></Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                </Nav>
                                <Nav>
                                    <Nav.Link as={Link} to="/new-admin" className="ms-3">New Admin</Nav.Link>
                                    <Nav.Link as={Link} to="/new-slider" className="ms-3">New Slider</Nav.Link>
                                    <Nav.Link as={Link} to="/add-trending-item" className='ms-3'>Add Trending Item</Nav.Link>
                                    <Nav.Link as={Link} to="/add-laptop-category" className="ms-3" >Laptop Category</Nav.Link>
                                    <Nav.Link as={Link} to="/add-new-laptop" className="ms-3">Add New Laptop</Nav.Link>
                                    <Nav.Link as={Link} to="/mobile" className="ms-3">Add New Mobile</Nav.Link>
                                    <Nav.Link as={Link} to="/mens-fashion" className="ms-3">Add New Men's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/womens-fashion" className="ms-3">Add New Women's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/sound" className="ms-3">Add New Audio</Nav.Link>
                                    <Nav.Link className='ms-3'><button onClick={(e) => logout()} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>
                                        Delete
                                    </button></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 justify-content-between">
                        <h2>Add Laptop Category</h2>
                        {/* onSubmit={handleSubmit} */}
                        <form onSubmit={handleSubmit}>
                            <div className="form-group px-3 py-3">
                                <label for="lapicat" className='mb-2'>Enter Category Name</label>
                                <input type="text" name='cat_name' value={inputs.cat_name} onChange={handleChange} id="lapicat" className="form-control" placeholder="Enter Laptop Category Name" />
                            </div>
                            <div className="form-group px-3 py-1">
                                <label for="cat_desc" className='mb-2'>Enter Category Description</label>
                                <textarea id="cat_desc" name="description" rows="4" value={inputs.description} onChange={handleChange} placeholder='Category Description' cols="60"></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary mt-2">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );

    // For Logout Admin
    function logout() {
        const conf = window.confirm("Do You Want LogOut");
        if (conf) {
            const activeUserId = readCookie('AdminId');
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/logout/' + activeUserId).then(res => {
            //     alert(res.data.message);
            //     removeCookie("AdminId");
            //     navigate('/login-admin');
            // }).catch(err => console.log(err));
            //  ONLine Database Url
            axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/logout/' + activeUserId).then(res => {
                alert(res.data.message);
                removeCookie("AdminId");
                navigate('/login-admin');
            }).catch(err => console.log(err));
        }
    }
}