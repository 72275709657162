import { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useCookies } from 'react-cookie';
export default function AddLaptopCategory() {
    const ref = useRef(null);
    const ref1 = useRef(null);


    const [catData, setcatData] = useState([]);
    useEffect(() => {
        checkAdminLogin();
        getImgData();
    }, []);


    // Function For Check Admin Is Loged In Or Not
    function checkAdminLogin() {
        const myCookieValue = readCookie('AdminId');

        if (!myCookieValue) {
            navigate('/login-admin')
        }
    }
    //  End Of Getting Cookies
    function getImgData() {
        // Offline Database URL
        // axios.get('http://localhost:80/Sam_API/ShowLaptopcategory').then(function (response) {
        //     setcatData(response.data);
        // });

        // Online Database Url
        // Offline Database URL
        axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/ShowLaptopcategory').then(function (response) {
            setcatData(response.data);
        });
    }






    const [inputs, setInputs] = useState({})
    const navigate = useNavigate();
    const [message, setMessage] = useState({
        cat_name: "",
        cat_desc: ""
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        ref.current.value = '';
        ref1.current.value = '';
        // Offline Databse URL
        // axios.post('http://localhost:80/Sam_API/AddLaptopcategory', inputs).then(function (response) {
        //     alert(response.data.message);
        //     // console.log(response.data);
        //     navigate(0);
        // });

        // Online DataBase
        // Offline Databse URL
        axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/AddLaptopcategory', inputs).then(function (response) {
            alert(response.data.message);
            // console.log(response.data);
            navigate(0);
        });
    }
    const [cookies, setCookie, removeCookie] = useCookies();

    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };
    return (
        <>
            <div className="container py-5 px-5">
                <div className='row'>
                    <div className='col-md-8'>
                        <Navbar expand="lg" >
                            <Navbar.Brand href="#" className='py-2 pl-2'><b>Admin Links</b></Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                </Nav>
                                <Nav>
                                    <Nav.Link as={Link} to="/new-admin" className="ms-3">New Admin</Nav.Link>
                                    <Nav.Link as={Link} to="/new-slider" className="ms-3">New Slider</Nav.Link>
                                    <Nav.Link as={Link} to="/add-trending-item" className='ms-3'>Add Trending Item</Nav.Link>
                                    <Nav.Link as={Link} to="/add-laptop-category" className="ms-3" >Laptop Category</Nav.Link>
                                    <Nav.Link as={Link} to="/add-new-laptop" className="ms-3">Add New Laptop</Nav.Link>
                                    <Nav.Link as={Link} to="/mobile" className="ms-3">Add New Mobile</Nav.Link>
                                    <Nav.Link as={Link} to="/mens-fashion" className="ms-3">Add New Men's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/womens-fashion" className="ms-3">Add New Women's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/sound" className="ms-3">Add New Audio</Nav.Link>
                                    <Nav.Link className='ms-3'><button onClick={(e) => logout()} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>
                                        Delete
                                    </button></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-3">
                        <Link as='Link' to='/show-all-laptop-category' className='btn btn-outline-primary'>Show All Category</Link>
                    </div>
                    <div className="col-md-7 justify-content-between card py-4 px-4">
                        <h2 className='text-center'>Add Laptop Category</h2>
                        {/* onSubmit={handleSubmit} */}
                        <form onSubmit={handleSubmit}>
                        <input type="hidden" name="MAX_FILE_SIZE" value="8000000000"/>
                            <div className="form-group px-3 py-3">
                                <label for="lapicat" className='mb-2'>Enter Category Name</label>
                                <input type="text" ref={ref} name='cat_name' id="lapicat" className="form-control" onChange={handleChange} placeholder="Enter Laptop Category Name" />
                            </div>
                            <div className="form-group px-3 py-1">
                                <label for="cat_desc" className='mb-2'>Enter Category Description</label><br></br>
                                <textarea id="cat_desc" ref={ref1} name="cat_desc" rows="4" onChange={handleChange} placeholder='Category Description' cols="60"></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary mt-2">Submit</button>
                        </form>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 mt-5'>
                        <table className='table table-hover' cellSpacing="10">
                            <thead>
                                <tr>
                                    <th colSpan="8" className='text-center'><h2>All Active Laptop Category Here</h2></th>
                                </tr>
                                <tr className='text-center'>
                                    <th scope="col">Sr.No</th>
                                    <th scope="col">Category Name</th>
                                    <th scope="col">Category Description</th>
                                    <th scope="col">Active</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Updated At</th>
                                    <th scope="col">Update Category</th>
                                    <th scope="col">Delete Category</th>
                                </tr>
                            </thead>
                            <tbody>
                                {catData.map((catData, key) =>
                                    <tr key={key} className="text-center">
                                        {/* <td>{catData.id}</td> */}
                                        <td>{key + 1}</td>
                                        {/* <td><img src={imgData.img_url} width="100px" height="100px" /></td> */}
                                        <td>{catData.cat_name}</td>
                                        <td>{catData.description}</td>
                                        <td>{catData.active == 1 ? <button onClick={e => inactiveLapiCat(catData.id)} className="btn btn-outline-primary">Active</button>
                                            : <button onClick={e => activeLapiCat(catData.id)} className="btn btn-outline-danger">In Active</button>}</td>
                                        <td>{catData.created_at}</td>
                                        <td>{catData.updated_at}</td>
                                        <td>
                                            <Link to={`/editlaptopcategory/${catData.id}`} className="btn btn-outline-success" style={{ marginRight: "10px" }}>Edit</Link>
                                        </td>
                                        <td>
                                            <button onClick={e => deleteSubmit(catData.id)} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>Delete</button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );

    // For Logout Admin
    function logout() {
        const conf = window.confirm("Do You Want LogOut");
        if (conf) {
            const activeUserId = readCookie('AdminId');
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/logout/' + activeUserId).then(res => {
            //     alert(res.data.message);
            //     removeCookie("AdminId");
            //     navigate('/login-admin');
            // }).catch(err => console.log(err));

            // Online Database Url
            axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/logout/' + activeUserId).then(res => {
                alert(res.data.message);
                removeCookie("AdminId");
                navigate('/login-admin');
            }).catch(err => console.log(err));
        }
    }

    //  For InActive Laptop Category
    function inactiveLapiCat(id) {
        const conf = window.confirm("Do You Want To In Active This Category");
        if (conf) {
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/inactivelaptopcategory/' + id).then(res => {
            //     alert('Category has In active..');
            //     // setMessage(res.message);
            //     navigate(0);
            // }).catch(err => console.log(err));


            //  Online Database Url
            axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/inactivelaptopcategory/' + id).then(res => {
                alert('Category has In active..');
                // setMessage(res.message);
                navigate(0);
            }).catch(err => console.log(err));
        }
    }

    //  For Active Laptop Category
    function activeLapiCat(id) {
        const conf = window.confirm("Do You Want To Active This Category");
        if (conf) {
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/activelaptopcategory/' + id).then(res => {
            //     alert('Category has Activated..');
            //     // setMessage(res.message);
            //     navigate(0);
            // }).catch(err => console.log(err));



            //  Online Database Url
            axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/activelaptopcategory/' + id).then(res => {
                alert('Category has Activated..');
                // setMessage(res.message);
                navigate(0);
            }).catch(err => console.log(err));
        }
    }
    function deleteSubmit(id) {
        const conf = window.confirm("Do You Want To Delete This Slider");
        if (conf) {
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/deletelaptopcategory/' + id).then(res => {
            //     alert('Record  has Deleted');
            //     setMessage(res.message);
            //     navigate(0);
            // }).catch(err => console.log(err));


            //  Offline Database Url
            axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/deletelaptopcategory/' + id).then(res => {
                alert('Record  has Deleted');
                setMessage(res.message);
                navigate(0);
            }).catch(err => console.log(err));
        }
    }
}