import { useState, state, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import { useRef } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useCookies } from 'react-cookie';
export default function AddSliderImg() {
    const navigate = useNavigate();
    const ref = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [imgData, setimgData] = useState([]);
    useEffect(() => {
        checkAdminLogin();
        getImgData();
    }, []);

    // Function For Check Admin Is Loged In Or Not
    function checkAdminLogin() {
        const myCookieValue = readCookie('AdminId');

        if (!myCookieValue) {
            navigate('/login-admin')
        }
    }
    function getImgData() {
        // Offline Database URL
        // axios.get('http://localhost:80/Sam_API/ShowSliderImg').then(function (response) {
        //     setimgData(response.data);
        // });
        // ONLine Database URL
        axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/ShowSliderImg').then(function (response) {
            setimgData(response.data);
        });
    }
    const [inputs, setInputs] = useState({})
    const handleChange = (event) => {
        const imgUrl = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [imgUrl]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        ref.current.value = '';
        ref1.current.value = '';
        ref2.current.value = '';
        // Offline Databse URL
        // axios.post('http://localhost:80/Sam_API/AddNewSliderImg', inputs).then(function (response) {
        //     alert(response.data.message);
        //     navigate(0);
        // });
        // ONLine Databse URL
        axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/AddNewSliderImg', inputs).then(function (response) {
            alert(response.data.message);
            navigate(0);
        });
    }
    const [cookies, setCookie, removeCookie] = useCookies();

    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };
    return (
        <>
            <div className="container py-5 px-5">
                <div className='row'>
                    <div className='col-md-8'>
                        <Navbar expand="lg" >
                            <Navbar.Brand href="#" className='py-2 pl-2'><b>Admin Links</b></Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                </Nav>
                                <Nav>
                                    <Nav.Link as={Link} to="/new-admin" className="ms-3">New Admin</Nav.Link>
                                    <Nav.Link as={Link} to="/new-slider" className="ms-3">New Slider</Nav.Link>
                                    <Nav.Link as={Link} to="/add-trending-item" className='ms-3'>Add Trending Item</Nav.Link>
                                    <Nav.Link as={Link} to="/add-laptop-category" className="ms-3" >Laptop Category</Nav.Link>
                                    <Nav.Link as={Link} to="/add-new-laptop" className="ms-3">Add New Laptop</Nav.Link>
                                    <Nav.Link as={Link} to="/mobile" className="ms-3">Add New Mobile</Nav.Link>
                                    <Nav.Link as={Link} to="/mens-fashion" className="ms-3">Add New Men's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/womens-fashion" className="ms-3">Add New Women's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/sound" className="ms-3">Add New Audio</Nav.Link>
                                    <Nav.Link className='ms-3'><button onClick={(e) => logout()} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>
                                        Delete
                                    </button></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 justify-content-between card py-4 px-4">
                        <h2 className='text-center'>Add New Slider Image</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label for="imgUrl" className='mt-2'>Enter Slider Image URL</label>
                                <input type="text" autoComplete='off' ref={ref} name='imgUrl' id="imgUrl" className="form-control mt-2" onChange={handleChange} placeholder="Enter Slider Image URL" />
                            </div>
                            <div className="form-group">
                                <label for="alt_text" className='mt-2'>Enter Alt Text</label>
                                <input type="text" ref={ref1} autoComplete='off' name='alt_text' id="alt_text" className="form-control mt-2" onChange={handleChange} placeholder="Enter Alt Text" />
                            </div>
                            <div className="form-group">
                                <label for="ImgProUrl" className='mt-2'>Enter Slider Image Product Url</label>
                                <input type="text" ref={ref2} autoComplete='off' name='ImgProUrl' id="ImgProUrl" className="form-control mt-2" onChange={handleChange} placeholder="Enter Slider Image Product URL" />
                            </div>
                            <button type="submit" className="btn btn-primary mt-2">Submit</button>
                        </form>
                    </div>
                </div>
                <div className='row'>

                    <div className='col-md-7 mt-5'>
                        <table className='table table-hover' cellSpacing="10">
                            <thead>
                                <tr>
                                    <th colSpan="6" className='text-center'><h2>Slider Image Here</h2></th>
                                </tr>
                                <tr className='text-center'>
                                    <th scope="col">id</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Alt Text</th>
                                    <th scope="col">Image Product URL</th>
                                    <th scope="col">Updated At</th>
                                    <th scope="col">Update</th>
                                    <th scope="col">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {imgData.map((imgData, key) =>
                                    <tr key={key} className="text-center">
                                        <td>{++key}</td>
                                        <td><img src={imgData.img_url} width="150px" height="60px" /></td>
                                        <td>{imgData.alt_text}</td>
                                        <td>{imgData.ImgProUrl}</td>
                                        <td>{imgData.updated_at}</td>
                                        <td>
                                            <Link to={`/editSliderImg/${imgData.id}`} className="btn btn-outline-success" style={{ marginRight: "10px" }}>Edit</Link>
                                        </td>
                                        <td>
                                            <button onClick={e => deleteSubmit(imgData.id)} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>Delete</button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
      // For Logout Admin
  function logout() {
    const conf = window.confirm("Do You Want LogOut");
    if (conf) {
      const activeUserId = readCookie('AdminId');
      //  Offline Database Url
    //   axios.post('http://localhost:80/Sam_API/logout/' + activeUserId).then(res => {
    //     alert(res.data.message);
    //     removeCookie("AdminId");
    //     navigate('/login-admin');
    //   }).catch(err => console.log(err));
      //  ONLine Database Url
      axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/logout/' + activeUserId).then(res => {
        alert(res.data.message);
        removeCookie("AdminId");
        navigate('/login-admin');
      }).catch(err => console.log(err));
    }
  }
    function deleteSubmit(id) {
        const conf = window.confirm("Do You Want To Delete This Slider");
        if (conf) {
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/deleteSlider/' + id).then(res => {
            //     alert(res.data.message);
            //     navigate(0);
            // }).catch(err => console.log(err));
            //  ONLine Database Url
            axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/deleteSlider/' + id).then(res => {
                alert(res.data.message);
                navigate(0);
            }).catch(err => console.log(err));
        }
    }
}