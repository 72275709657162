import React, { Component } from 'react';
import { useEffect, useState } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import Carousel from 'react-bootstrap/Carousel';
import '../App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function Dash() {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getData();
    }, []);
    function getData() {
        // Offilne Database Url
        // axios.get('http://localhost:80/Sam_API/users/').then(function (response) {
        //     console.log(response.data);
        //     setUsers(response.data);
        // });
        // Online Database URL
        axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/users/').then(function (response) {
            console.log(response.data);
            setUsers(response.data);
        });
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <Carousel data-bs-theme="dark">
                            {users.map((user, key) =>
                                <Carousel.Item>
                                    <a href='https://www.amazon.in/b?node=66535976031'>
                                    <img
                                        className="d-block w-100 h-50"
                                        src={user.img}
                                        alt="First slide"
                                    /></a>
                                </Carousel.Item>
                            )}
                            {/* <Carousel.Item>
                                <a href='https://www.amazon.in/b?node=66535976031'>
                                    <img
                                        className="d-block w-100 h-50"
                                        src="https://m.media-amazon.com/images/G/31/img23/HI/June/HMD/HI_HMD_DEC_associate.jpg"
                                        alt="First slide"
                                    /></a>
                            </Carousel.Item>
                            <Carousel.Item>
                                <a href='https://www.amazon.in/b?node=21541572031&ref=ac_in_carousel-SLOT5' >
                                    <img
                                        className="d-block w-100 h-50"
                                        src="https://m.media-amazon.com/images/G/31/img22/WLA/2023/MAD/Dec23/associates/D106587418_Associates_2148x588_4new.jpg"
                                        alt="Second slide"
                                    /></a>
                            </Carousel.Item>
                            <Carousel.Item>
                                <a href='https://www.amazon.in/b?node=28178790031&ref=ac_in_carousel-SLOT6' >
                                    <img
                                        className="d-block w-100 h-50"
                                        src="https://m.media-amazon.com/images/G/31/img21/shoes/2023/DECWRS/DecWRS/Deals_on_Heels_2148x588.png"
                                        alt="Third slide"
                                    /></a>
                            </Carousel.Item>
                            <Carousel.Item>
                                <a href='https://amzn.to/3TkgdlT' >
                                    <img
                                        className="d-block w-100 h-50"
                                        src="https://m.media-amazon.com/images/G/31/img21/TVs/Nireeksh/December23/D106460294_IN_HETV_Samsung-CUE70-Launch_Associate_Graphics_Template_2148x588_New.jpg"
                                        alt="Forth slide"
                                    /></a>
                            </Carousel.Item> */}
                        </Carousel>
                    </div>
                </div>
            </div>


        </>
    )
}
