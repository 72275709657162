import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
export default function Database() {
    const navigate = useNavigate();
    const [inputs, setInputs] =useState({})
    const handleChange = (event)=>{
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values,[name]:value}));
    }
    const handleSubmit = (event)=>{
        event.preventDefault();
        // Offline Databse URL
        // axios.post('http://localhost:80/Sam_API/user/save',inputs).then(function(response){
        //     console.log(response.data);
        //     navigate('/showdata');
        // });
        // Online Database URL
        axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/user/save',inputs).then(function(response){
            console.log(response.data);
            navigate('/showdata');
        });
       
    }
    return (
        <>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" className="form-control" onChange={handleChange} placeholder="Enter Name" name='name' />
                            </div>
                            <div className="form-group">
                                <label>Email address</label>
                                <input type="email" name='email' className="form-control" onChange={handleChange} placeholder="Enter email" />
                            </div>
                            
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                    <div className='col-md-3'></div>
                </div>
            </div>
        </>
    )
}