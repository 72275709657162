import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useRef } from 'react';
import { useCookies } from 'react-cookie';
export default function AddLaptop() {
    const navigate = useNavigate();

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);
    const ref11 = useRef(null);
    const [catData, setCatData] = useState([]);

    useEffect(() => {
        checkAdminLogin();
        getCatData();
    }, []);

     // Function For Check Admin Is Loged In Or Not
     function checkAdminLogin() {
        const myCookieValue = readCookie('AdminId');

        if (!myCookieValue) {
            navigate('/login-admin')
        }
    }
    function getCatData() {
        // Offline Database URL
        // axios.get(`http://localhost:80/Sam_API/getallLaptopCat`).then(function (response) {
        //     if (response.data == '') {
        //         alert('Laptop Category Unavailable');
        //         navigate('/add-laptop-category');
        //     } else {
        //         setCatData(response.data);
        //     }
        // });
        // ONLine Databse URL
        axios.get(`https://amolkhotevibe.000webhostapp.com/Sam_API/getallLaptopCat`).then(function (response) {
            if (response.data == '') {
                alert('Laptop Category Unavailable');
                navigate('/add-laptop-category');
            } else {
                setCatData(response.data);
            }
        });
    }

    const [inputs, setInputs] = useState({})
    const handleChange = (event) => {
        const imgUrl = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [imgUrl]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        ref1.current.value = ''; ref2.current.value = ''; ref3.current.value = '';
        ref4.current.value = ''; ref5.current.value = ''; ref6.current.value = '';
        ref7.current.value = ''; ref8.current.value = ''; ref9.current.value = '';
        ref10.current.value = ''; ref11.current.value = '';
        // Offline Databse URL
        // axios.post('http://localhost:80/Sam_API/AddNewLaptopProduct', inputs).then(function (response) {
        //     alert(response.data.message);
        //     navigate(0);
        // });
        // ONLine Databse URL
        axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/AddNewLaptopProduct', inputs).then(function (response) {
            alert(response.data.message);
            navigate(0);
        });
    }
    const [cookies, setCookie, removeCookie] = useCookies();

    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };
       // For Logout Admin
  function logout() {
    const conf = window.confirm("Do You Want LogOut");
    if (conf) {
      const activeUserId = readCookie('AdminId');
      //  Offline Database Url
    //   axios.post('http://localhost:80/Sam_API/logout/' + activeUserId).then(res => {
    //     alert(res.data.message);
    //     removeCookie("AdminId");
    //     navigate('/login-admin');
    //   }).catch(err => console.log(err));
      //  ONLine Database Url
      axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/logout/' + activeUserId).then(res => {
        alert(res.data.message);
        removeCookie("AdminId");
        navigate('/login-admin');
      }).catch(err => console.log(err));
    }
  }
    return (
        <>
            <div className="container py-5 px-5">
                <div className='row'>
                    <div className='col-md-8'>
                        <Navbar expand="lg" >
                            <Navbar.Brand href="#" className='py-2 pl-2'><b>Admin Links</b></Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                </Nav>
                                <Nav>
                                    <Nav.Link as={Link} to="/new-admin" className="ms-3">New Admin</Nav.Link>
                                    <Nav.Link as={Link} to="/new-slider" className="ms-3">New Slider</Nav.Link>
                                    <Nav.Link as={Link} to="/add-trending-item" className='ms-3'>Add Trending Item</Nav.Link>
                                    <Nav.Link as={Link} to="/add-laptop-category" className="ms-3" >Laptop Category</Nav.Link>
                                    <Nav.Link as={Link} to="/add-new-laptop" className="ms-3">Add New Laptop</Nav.Link>
                                    <Nav.Link as={Link} to="/mobile" className="ms-3">Add New Mobile</Nav.Link>
                                    <Nav.Link as={Link} to="/mens-fashion" className="ms-3">Add New Men's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/womens-fashion" className="ms-3">Add New Women's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/sound" className="ms-3">Add New Audio</Nav.Link>
                                    <Nav.Link className='ms-3'><button onClick={(e) => logout()} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>
                                        Delete
                                    </button></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
                <div className='row py-3'>
                    <div className='col-md-2'>
                        <Link as={Link} to="/show-all-laptop" className="btn btn-outline-info mt-2 px-1 py-1">Show All Laptop</Link>
                    </div>
                    <div className="col-md-8 justify-content-between card py-4 px-4 ">
                        <h2 className='text-center'>Add New Laptop</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label for="cat_id">Select Laptop Category</label>
                                <select ref={ref11} onChange={handleChange} name="cat_id" id="cat_id" className="form-control mt-2">
                                    <option>Select Laptop Category</option>
                                    {catData.map((catData, key) =>
                                        <option value={catData.id}>{catData.cat_name}</option>

                                    )}
                                </select>
                            </div>
                            <div className="form-group mt-2">
                                <label for="laptop_name">Enter Laptop Name</label>
                                <input type="text" autoComplete='off' ref={ref1} name='laptop_name' id="laptop_name" className="form-control mt-2" onChange={handleChange} placeholder="Enter Laptop Name" />
                            </div>
                            <div className="form-group mt-2">
                                <label for="MRP">Enter Laptop MRP </label>
                                <input type="text" ref={ref2} autoComplete='off' name='MRP' id="MRP" className="form-control mt-2" onChange={handleChange} placeholder="Enter Laptop MRP" />
                            </div>
                            <div className="form-group mt-2">
                                <label for="price">Enter Laptop price </label>
                                <input type="text" ref={ref3} autoComplete='off' name='price' id="price" className="form-control mt-2" onChange={handleChange} placeholder="Enter Laptop price" />
                            </div>
                            <div className="form-group mt-2">
                                <label for="discount">Enter Discount On Laptop </label>
                                <input type="text" ref={ref4} autoComplete='off' name='discount' id="discount" className="form-control mt-2" onChange={handleChange} placeholder="Enter Discount On Laptop " />
                            </div>
                            <div className="form-group mt-2">
                                <label for="emi">Enter EMI Starting With </label>
                                <input type="text" ref={ref5} autoComplete='off' name='emi' id="emi" className="form-control mt-2" onChange={handleChange} placeholder="Enter EMI Starting With " />
                            </div>
                            <div className="form-group mt-2">
                                <label for="description">Enter Laptop Description </label>
                                <input type="text" ref={ref6} autoComplete='off' name='description' id="description" className="form-control mt-2" onChange={handleChange} placeholder="Enter Laptop Description " />
                            </div>
                            <div className="form-group mt-2">
                                <label for="RAM_ROM">Enter RAM / SSD / HDD </label>
                                <input type="text" ref={ref7} autoComplete='off' name='RAM_ROM' id="RAM_ROM" className="form-control mt-2" onChange={handleChange} placeholder="Enter RAM / SSD / HDD" />
                            </div>
                            <div className="form-group mt-2">
                                <label for="display">Enter Display Properties </label>
                                <input type="text" ref={ref8} autoComplete='off' name='display' id="display" className="form-control mt-2" onChange={handleChange} placeholder="Enter Display Properties" />
                            </div>
                            <div className="form-group mt-2">
                                <label for="body">Enter Laptop Body Type </label>
                                <input type="text" ref={ref9} autoComplete='off' name='body' id="body" className="form-control mt-2" onChange={handleChange} placeholder="Enter Laptop Body Type" />
                            </div>
                            <div className="form-group mt-2">
                                <label for="product_link">Enter Laptop Buy Link </label>
                                <input type="text" ref={ref10} autoComplete='off' name='product_link' id="product_link" className="form-control mt-2" onChange={handleChange} placeholder="Enter Laptop Buy Link" />
                            </div>
                            <div className="form-group mt-2">
                                <label for="product_img_link">Enter Laptop Buy Link </label>
                                <input type="text" ref={ref10} autoComplete='off' name='product_img_link' id="product_img_link" className="form-control mt-2" onChange={handleChange} placeholder="Enter Laptop Image Link" />
                            </div>
                            <button type="submit" className="btn btn-primary mt-2">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}