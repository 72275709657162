import { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './Footer.css';
import { Link } from "react-router-dom";
export class Footer extends Component {
    render() {
        // const currentYear = new Date().getFullYearr();
        const Year = new Date().getFullYear();
        return (
            <>
                <footer className="footer-10 mt-5">
                    <div className="container">
                        {/* Start Of 1st Row */}
                        <div className="row mb-5 pb-3 no-gutters">
                            {/* Start 1st Col */}
                            <div className="col-md-4 mb-md-0 mb-4 d-flex">
                                <div className="con con-1 w-100 py-5">
                                    <div className="con-info w-100 text-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <i className="bi bi-whatsapp" />
                                        </div>
                                        <div className="text">
                                            <span>(+91) 9766964045</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End 1st Col */}
                            {/* Start 2sd Col */}
                            <div className="col-md-4 mb-md-0 mb-4 d-flex">
                                <div className="con con-2 w-100 py-5">
                                    <div className="con-info w-100 text-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <i className="bi bi-envelope" />
                                        </div>
                                        <div className="text">
                                            <span>amolkhote333@gmail.com</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End 2sd Col */}
                            {/* Start Of 3rd Col */}
                            <div className="col-md-4 mb-md-0 mb-4 d-flex">
                                <div className="con con-3 w-100 py-5">
                                    <div className="con-info w-100 text-center">
                                        <div className="icon d-flex align-items-center justify-content-center">
                                            <i class="bi bi-geo-alt-fill"></i>
                                        </div>
                                        <div className="text">
                                            <span>Shop no.07,Vishwadeep complex,Kurduwadi road,Tembhurni,413211</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Of 3rd Col */}
                        </div>
                        {/* End Of 1st Row */}
                        {/* Start Of 2nd Row */}
                        <div className="row">
                            <div className="col-md-7 text-center">
                                <div className="row">
                                    {/* Start Of 1st Col */}
                                    <div className="col-md-4 mb-md-0 mb-4">
                                        <h2 className="footer-heading">About</h2>
                                        <ul className="list-unstyled mt-3">
                                            <li><Link as={Link} to='/' className="d-block">Out story</Link></li>
                                            <li><Link as={Link} to='/' className="d-block">Awards</Link></li>
                                            <li><Link as={Link} to='/' className="d-block">Our Team</Link></li>
                                            <li><Link as={Link} to='/' className="d-block">Career</Link></li>
                                        </ul>
                                    </div>
                                    {/* End Of 1st Col */}
                                    {/* Start Of 2nd Col */}
                                    <div className="col-md-4 mb-md-0 mb-4">
                                        <h2 className="footer-heading">Company</h2>
                                        <ul className="list-unstyled mt-3">
                                            <li><Link as={Link} to='/' className="d-block">Our services</Link></li>
                                            <li><Link as={Link} to='/' className="d-block">Clients</Link></li>
                                            <li><Link as={Link} to='/' className="d-block">Contact</Link></li>
                                            <li><Link as={Link} to='/' className="d-block">Press</Link></li>
                                        </ul>
                                    </div>
                                    {/* End Of 2nd Col */}
                                    {/* Start Of 3rd Col */}
                                    <div className="col-md-4 mb-md-0 mb-4">
                                        <h2 className="footer-heading">Resources</h2>
                                        <ul className="list-unstyled mt-3">
                                            <li><Link as={Link} to='/' className="d-block">Blog</Link></li>
                                            <li><Link as={Link} to='/' className="d-block">Newsletter</Link></li>
                                            <li><Link as={Link} to='/privacy-policy' className="d-block">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                    {/* End Of 3rd Col */}
                                </div>
                            </div>
                            <div className="col-md-5 mb-md-0 mb-4">
                                    <h2 className="footer-heading">Subscribe</h2>
                                    <form action="#" className="subscribe-form mt-3">
                                        <div className="form-group d-flex">
                                            <input type="text" className="form-control rounded-left" placeholder="Enter email address" />
                                            <button type="submit" className="form-control submit rounded-right">Subscribe</button>
                                        </div>
                                        <span className="subheading">Get digital marketing updates in your mailbox</span>
                                    </form>
                                </div>
                        </div>
                        {/* End Of 2nd Row */}
                        {/* Start Of 3rd Row */}
                        <div className="row mt-5 pt-4 border-top">
                            <div className="col-md-6 col-lg-8 mb-md-0 mb-4">
                                <p className="copyright mb-0">
                                    Copyright &copy; {Year} All rights reserved.
                                </p>
                            </div>

                        </div>
                        {/* End Of 3rd Row */}
                    </div>
                </footer>
            </>
        )
    }
}