import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './Laptop.css';
import { Link, useNavigate } from 'react-router-dom'
import axios from "axios";
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
export default function LenovoLaptop(){
    const navigate = useNavigate();
    const [lapiData, setlapiData] = useState([]);
    useEffect(() => {
        getlapiData();
    }, []);
    function getlapiData() {
        // Offline Database URL
        // axios.get('http://localhost:80/Sam_API/getlenovoLaptop').then(function (response) {
        //     if(response.data == ''){
        //         alert('Lenovo Laptop Currently Unavailable'); 
        //         navigate('/laptop');               
        //     }else{
        //         setlapiData(response.data);
        //     }
        // });
        // ONLine Database URL
        axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/getlenovoLaptop').then(function (response) {
            if(response.data == ''){
                alert('Lenovo Laptop Currently Unavailable'); 
                navigate('/laptop');               
            }else{
                setlapiData(response.data);
            }
        });
    }
    return (
        <>
            <div className='container-fluid'>
                {/* Start Of SideBar Row */}
                <div className='row'>
                    {/* End Of Side Bar */}
                    {/* min-vh-100 */}
                    <div className='col-md-3 col-sm-2 nav-bg'>
                        <div className='d-flex justify-content-between flex-column'>
                            <div>
                                {/* d-none d-sm-inline */}
                                <a className='text-decoration-none text-dark  align-itemcenter'>
                                    <span className='ms-2 fs-4'>Laptop Menu</span>
                                </a>
                                <hr className='text-secondary'></hr>
                                <ul className='nav nav-pills flex-column'>
                                    <li className='nav-item text-white fs-5  py-2 py-sm-0'>
                                        <Link to="/top-10-laptop" className='nav-link text-dark' aria-current='page'>
                                            <i className='bi bi-laptop'></i>
                                            {/* <span className='ms-3 d-none d-sm-inline'>DashBoard</span> */}
                                            <span className='ms-2'>Top 10 Laptop</span>
                                        </Link>
                                    </li>
                                    <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                        <Link to="/lenovolaptop" className='nav-link text-dark' aria-current='page'>
                                            <i className='bi bi-laptop'></i>
                                            {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                            <span className='ms-2'>Lenovo Laptop</span>
                                        </Link>
                                    </li>
                                    <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                        <Link to="/dell-laptop" className='nav-link text-dark' aria-current='page'>
                                            <i className='bi bi-laptop'></i>
                                            {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                            <span className='ms-2'>Dell Laptop</span>
                                        </Link>
                                    </li>
                                    <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                        <Link to="/asus-laptop" className='nav-link text-dark' aria-current='page'>
                                            <i className='bi bi-laptop'></i>
                                            {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                            <span className='ms-2'>ASUS Laptop</span>
                                        </Link>
                                    </li>
                                    <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                        <Link to="/hp-laptop" className='nav-link text-dark' aria-current='page'>
                                            <i className='bi bi-laptop'></i>
                                            {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                            <span className='ms-2'>HP Laptop</span>
                                        </Link>
                                    </li>
                                    <hr className='text-secondary'></hr>
                                    <li className='nav-item text-dark fs-5 py-sm-0'>
                                        <Link className='nav-link text-dark' aria-current='page'>
                                            <b> <i className='bi bi-currency-rupee'></i></b>
                                            <span className='ms-2'> <b>Select Price</b></span>
                                        </Link>
                                    </li>
                                    <li className='nav-item text-dark fs-5 py-0 py-sm-0'>
                                        <Link to="/30k-40k" className='nav-link text-dark' aria-current='page'>
                                            <i className='bi bi-laptop'></i>
                                            {/* <span className='ms-3 d-none d-sm-inline'>Home</span> */}
                                            <span className='ms-2'>30,000 - 45,000</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* End Of Side Bar */}
                    {/* Call Here */}
                    {/* Adding New Laptop this Page */}
                
                    
                    {lapiData.map((proData, key) =>                       
                        <div className='col-md-3 col-sm-3 mt-3'>
                           
                            <Card >
                                <a href={proData.product_link} target="_blank">
                                    <Card.Img variant="top" src={proData.product_img_link} />
                                </a>
                                <Card.Body>
                                    <strong><span className="discount">{proData.discount}</span> <i className='bi bi-currency-rupee'>{proData.price}</i>  </strong><br />
                                    <del className="delTag">M.R.P. : ₹{proData.MRP}</del><br />
                                    <span className="emiText">{proData.emi}</span><br />
                                    <Card.Title>{proData.laptop_name}</Card.Title>
                                    <Card.Text>
                                        {proData.description}
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item> {proData.RAM_ROM}</ListGroup.Item>
                                    <ListGroup.Item>{proData.display}</ListGroup.Item>
                                    <ListGroup.Item>{proData.body}</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <a href={proData.product_link} className='btn btn-outline-success' target='_blank'>Buy Now</a>
                                </Card.Body>
                            </Card>
                        </div>
                        
                    )}
                </div>
            </div>
        </>
    )
}
