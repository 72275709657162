import { useEffect, useState } from "react"
import axios from 'axios';
import { useResetProjection } from "framer-motion";
export default function ShowData() {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        getData();
    }, []);
    function getData() {
        // Offline Database URL
        axios.get('http://localhost:80/Sam_API/users/').then(function (response) {
            console.log(response.data);
            setUsers(response.data);
        });
        // Online Database URL
        // axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/users').then(function (response) {
        //     console.log(response.data);
        //     setUsers(response.data);
        // });
    }
    return (
        <>
            <h1>Show Data</h1>
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Name</th>
                        <th>email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, key) =>
                        <tr key={key}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            {/* <td><img src={user.img} /></td> */}
                            <td>{user.email}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    )
}