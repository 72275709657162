import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useCookies } from 'react-cookie';
export default function EditAdmin() {

    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };

    //  End Of Getting Cookies

    const [inputs, setInputs] = useState([]);
    const navigate = useNavigate(['username']);
    const { id } = useParams();

    const [cookies, setCookies, removeCookie] = useCookies();

    useEffect(() => {
        checkAdminLogin();
        getinput();
    }, []);
    // Function For Check Admin Is Loged In Or Not
    function checkAdminLogin() {
        const myCookieValue = readCookie('AdminId');
        if (!myCookieValue) {
            navigate('/login-admin')
        }
    }
    function getinput() {
        // Offline Database URL
        // axios.get(`http://localhost:80/Sam_API/getAdminData/${id}`).then(function (response) {
        //     if (response.data == '') {
        //         alert("Data Not Found");
        //         navigate('/new-admin');
        //     } else {
        //         setInputs(response.data);
        //     }
        // });



        // Online Database URL
        axios.get(`https://intrendingvibe.com/Sam_API/getAdminData/${id}`).then(function (response) {
            if (response.data == '') {
                alert("Data Not Found");
                navigate('/new-admin');
            } else {
                setInputs(response.data);
            }
        });

    }
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Url For OffLine Database
        // axios.post(`http://localhost:80/Sam_API/updateAdmin/${id}`, inputs).then(function (response) {
        //     if (response.data.status == 1) {
        //         alert(response.data.message);
        //         navigate('/new-admin');
        //     } else {
        //         alert(response.data.message);
        //     }
        // });

        // Url For ONLine Database
        axios.post(`https://intrendingvibe.com/Sam_API/updateAdmin/${id}`, inputs).then(function (response) {
            if (response.data.status == 1) {
                alert(response.data.message);
                navigate('/new-admin');
            } else {
                alert(response.data.message);
            }
        });
    }
    return (
        <div className="container py-5 px-5">
            <div className="row">
                <div className="col-md-8">
                    <Navbar expand="lg">
                        <Navbar.Brand href="#" className="py-2 pl-2">
                            <b>Admin Links</b>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll></Nav>
                            <Nav>
                                <Nav.Link as={Link} to="/new-admin" className="ms-3">New Admin</Nav.Link>
                                <Nav.Link as={Link} to="/new-slider" className="ms-3">New Slider</Nav.Link>
                                <Nav.Link as={Link} to="/add-trending-item" className='ms-3'>Add Trending Item</Nav.Link>
                                <Nav.Link as={Link} to="/add-laptop-category" className="ms-3" >Laptop Category</Nav.Link>
                                <Nav.Link as={Link} to="/add-new-laptop" className="ms-3">Add New Laptop</Nav.Link>
                                <Nav.Link as={Link} to="/mobile" className="ms-3">Add New Mobile</Nav.Link>
                                <Nav.Link as={Link} to="/mens-fashion" className="ms-3">Add New Men's Fashion</Nav.Link>
                                <Nav.Link as={Link} to="/womens-fashion" className="ms-3">Add New Women's Fashion</Nav.Link>
                                <Nav.Link as={Link} to="/sound" className="ms-3">Add New Audio</Nav.Link>
                                <Nav.Link className='ms-3'><button onClick={(e) => logout()} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>
                                    Delete
                                </button></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
            <div className="row py-5">
                <div className="col-md-2"></div>
                <div className="col-md-8 card py-4 px-4">
                    <h2 className="text-center">Update Admin</h2>
                    {/* <div className='col-md-6'> */}
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="mt-2" for="name">Name</label>
                            <input type="text" autoComplete='off' value={inputs.name} className="form-control mt-2" onChange={handleChange} placeholder="Enter Name" name="name" />
                        </div>
                        <div className="form-group">
                            <label className="mt-2" for="username">Username</label>
                            <input type="text" autoComplete='off' value={inputs.username} className="form-control mt-2" onChange={handleChange} placeholder="Enter Username" name="username" />
                        </div>
                        <div className="form-group">
                            <label className="mt-2" for="email">Email address</label>
                            <input type="email" name="email" autoComplete='off' value={inputs.email} className="form-control mt-2" onChange={handleChange} placeholder="Enter email" />
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );

    // For Logout Admin
    function logout() {
        const conf = window.confirm("Do You Want LogOut");
        if (conf) {
            const activeUserId = readCookie('AdminId');
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/logout/' + activeUserId).then(res => {
            //     alert(res.data.message);
            //     removeCookie("AdminId");
            //     navigate('/login-admin');
            // }).catch(err => console.log(err));


            //  Online Database Url
            axios.post('https://intrendingvibe.com/Sam_API/logout/' + activeUserId).then(res => {
                alert(res.data.message);
                removeCookie("AdminId");
                navigate('/login-admin');
            }).catch(err => console.log(err));
        }
    }
}