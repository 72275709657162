import { Component, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { NavLink } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../App.css';
import './SideBar.css';
import { Link } from 'react-router-dom';
import { Footer } from "../Pages/Footer/Footer";

var routes = [
    {
        path: '/',
        name: "home",
        icon: <i className="bi bi-house" />,
    },
    {
        path: '/womens-fashion',
        name: "Women's Clothing",
        icon: <i className="bi bi-gender-female" />,
    },
    {
        path: '/women-accessories',
        name: "Women's Accessories",
        icon: <i className="bi bi-gender-female" />,
    },
    {
        path: '/mens-fashion',
        name: "Men's Clothing",
        icon: <i className="bi bi-gender-male" />,
    },
    {
        path: '/men-accessories',
        name: "Men's Accessories",
        icon: <i className="bi bi-gender-male" />,
    },
    {
        path: '/sound',
        name: "Audio",
        icon: <i className="bi bi-volume-up" />,
    },
];

const SideBar = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        show: {
            width: 'auto',
            opacity: 1,
            transition: {
                duration: 0.2,
            }
        },
    }

    return (
        <>
            <div className='container-fluid nav-bg'>
                {/* Start Of Nav Bar */}
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <Navbar expand="lg" >
                                <Navbar.Brand href="#" className='py-2 pl-2'><Nav.Link as={Link} to='/'><b>TRENDING VIBE</b></Nav.Link></Navbar.Brand>
                                <Navbar.Toggle aria-controls="navbarScroll" />
                                <Navbar.Collapse id="navbarScroll">
                                    <Nav
                                        className="me-auto my-2 my-lg-0"
                                        style={{ maxHeight: '100px' }}
                                        navbarScroll
                                    >
                                    </Nav>
                                    <Nav>
                                        <Nav.Link as={Link} to="/" className='ms-3'>Home</Nav.Link>
                                        <Nav.Link as={Link} to="/laptop" className='ms-3'>Laptop</Nav.Link>
                                        {/* <Nav.Link as={Link} to="/computer" className='ms-3'>Computer</Nav.Link> */}
                                        <Nav.Link as={Link} to="/mobile" className='ms-3'>Mobile</Nav.Link>
                                        <Nav.Link as={Link} to="/mens-fashion" className='ms-3'>Men's Fashion</Nav.Link>
                                        <Nav.Link as={Link} to="/womens-fashion" className='ms-3'>Women's Fashion</Nav.Link>
                                        <Nav.Link as={Link} to="/sound" className='ms-3'>Audio</Nav.Link>
                                        {/* <Nav.Link as={Link} to="/login-admin" className='ms-3'>Admin Login</Nav.Link> */}
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-Container">
                <motion.div animate={{ width: isOpen ? "250px" : "50px" }} className="sidebar">
                    <div className="top-section">
                        {/* {isOpen && <motion.h5 inherit="hidden" animate="show" exit="hidden" variants={showAnimation}>Trending Vibe</motion.h5>} */}
                        <div className="bar mx-3">
                            <i class="bi bi-justify" onClick={toggle} ></i>
                        </div>
                    </div>
                    <section className="routes">
                        {routes.map((route) => (
                            <NavLink as={Link} to={route.path} key={route.name} className="link">
                                {/* <div className="bar">{route.icon}</div> */}
                                <AnimatePresence>
                                    <motion.div className="link_text" title={route.name}>
                                        {route.icon}{isOpen && <motion.div inherit="hidden" animate="show" exit="hidden" variants={showAnimation} className="link" >{route.name}</motion.div>}
                                    </motion.div>
                                </AnimatePresence>

                            </NavLink>
                        ))}
                    </section>
                </motion.div>
                <main className="container-fluid">
                    {children}
                </main>
            </div>

            <Footer></Footer>
        </>

    )
};


export default SideBar;