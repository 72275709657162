import React, { useState } from "react";
import './AdminLogin.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
export default function AdminLogin() {


    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };

    //  End Of Getting Cookies
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [email, setEmail] = useState("");

    const [password, setPassword] = useState("");


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };
    function validateForm() {
        return email.length > 0 && password.length > 0;
    }
    const [cookies, setCookies] = useCookies();
    function handleSubmit(event) {
        event.preventDefault();
        // For OffLine Database
        // axios.post('http://localhost:80/Sam_API/adminlogin', inputs).then(function (response) {
        //     if (response.data.status != 0) {
        //         const myCookieValue = readCookie('AdminId');
        //         if (!myCookieValue) {                    
        //             setCookies('AdminId', response.data.id);
        //             navigate('/new-admin');
        //         }else{
        //            navigate('/login-admin')
        //         }
                
        //     } else {
        //         alert(response.data.message);
        //     }

        // });


        
        // Url For Online Database 
        axios.post('https://intrendingvibe.com/Sam_API/adminlogin', inputs).then(function (response) {
            if (response.data.status !== 0) {
                const myCookieValue = readCookie('AdminId');
                if (!myCookieValue) {                    
                    setCookies('AdminId', response.data.id);
                    navigate('/new-admin');
                }else{
                   navigate('/login-admin')
                }
                
            } else {
                alert(response.data.message);
            }

        });
    }

    return (

        <div className="container px-lg-5 py-lg-5 mt-lg-5">
            <div class="row justify-content-center">
                <div class="col-md-5 col-lg-5">
                    <div class="login-wrap py-5">
                        <img class="img d-flex align-items-center justify-content-center" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ6EoD92dmmH-bAwRHvmCnHVjLTJL5VXrNIg&usqp=CAU" />
                        {/* <div class="img d-flex align-items-center justify-content-center"
                            style={{
                                backgroundImage:
                                    'url("./bg.jpg")', backgroundSize: "contain", backgroundRepeat: "no-repeat",
                            }}></div> */}
                        <h3 class="text-center mb-0">Welcome</h3>
                        <p class="text-center">Sign in by entering the information below</p>
                        <form onSubmit={handleSubmit} class="login-form">
                            <div class="form-group">
                                <div class="icon d-flex align-items-center justify-content-center"><span
                                    class="fa fa-user"></span></div>
                                <input type="text" name="email" class="form-control login" autoComplete="off" onChange={handleChange} placeholder="Username or Email" required />
                            </div>
                            <div class="form-group">
                                <div class="icon d-flex align-items-center justify-content-center"><span
                                    class="fa fa-lock"></span></div>
                                <input type="password" name="password" class="form-control login" onChange={handleChange} placeholder="Password" required />
                            </div>
                            <div class="form-group d-md-flex">
                                <div class="w-100 text-md-right">
                                    <a href="#">Forgot Password</a>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn form-control btn-primary1 rounded submit px-3">Get
                                    Started</button>
                            </div>
                        </form>
                        {/* <div class="w-100 text-center mt-4 text">
                            <p class="mb-0">Don't have an account?</p>
                            <Link as={Link} to='/reg-new-admin'>Sign Up</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}