import React, { Component } from 'react';
import { useEffect, useState } from "react"
import { ShowAllLaptop } from './Pages/Laptop/ShowAllLaptop';
import Demo from './Components/SideBar';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import Carousel from 'react-bootstrap/Carousel';
import './App.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function Dash() {
    // const BaseUrl = 'http://localhost:80/Sam_API/';
    const BaseUrl = 'https://amolkhotevibe.000webhostapp.com/Sam_API/';
    const [img, setSliderImg] = useState([]);
    const [TrendingItem, setTrendingItem] = useState([]);
    useEffect(() => {
        getData();
    }, []);
    function getData() {
        // Offline Database URL
        // axios.get('http://localhost:80/Sam_API/getsliderimg').then(function (response) {
        //     setSliderImg(response.data);
        // });
        // axios.get('http://localhost:80/Sam_API/getTrendingItem').then(function (response) {
        //     setTrendingItem(response.data);
        // });       
        // ONLine Database URL
        axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/getsliderimg').then(function (response) {
            setSliderImg(response.data);
        });
        axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/getTrendingItem').then(function (response) {
            setTrendingItem(response.data);
        });       
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <Carousel data-bs-theme="dark">
                            {img.map((imgData, key) =>
                                <Carousel.Item>
                                    <Link as={Link} to={imgData.ImgProUrl} target='_blank'>
                                        <img
                                            className="d-block w-100 h-50"
                                            src={imgData.img_url}
                                            alt={imgData.alt_text}
                                        /></Link>
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className='container mt-5'>
                {/* 1st Row Start */}
                <div className='row product-row'>
                    {TrendingItem.map((trending_item, key) =>             
                <div className='col-md-2 small-col'>
                            <Link as={Link} to={trending_item.ImgProUrl}>
                            <img class="card-img-top" src={trending_item.img_url} alt={trending_item.alt_text} />
                        </Link>
                    </div>
                    )} 
                </div>
                {/* 1st Row End */}
                {/* 2nd Row Start */}
                <div className='row mt-5'>
                    {/* <div col-md-1></div> */}
                    {/* Start Of 1st Col */}
                    <div className='col-md-3'>
                    <div className='row product-row'>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </Link>
                            </div>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='/laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </Link>
                            </div>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='/laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </Link>
                            </div>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='/laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* End Of 1st Col */}
                    {/* Start Of 2nd Col */}
                    <div className='col-md-1'></div>
                    <div className='col-md-3'>
                        <div className='row product-row'>
                            <div className='col-md-6 small-col'>
                                <a href='https://amzn.to/3Tem0cC' target="_blank">
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </a>
                            </div>
                            <div className='col-md-6 small-col'>
                                <a href='https://amzn.to/47VF3N5' target='_blank'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </a>
                            </div>
                            <div className='col-md-6 small-col'>
                                <a href='https://amzn.to/47VF3N5' target='_blank'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </a>
                            </div>
                            <div className='col-md-6 small-col'>
                                <a href='https://amzn.to/47VF3N5' target='_blank'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </a>                                
                            </div>
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                    {/* End Of 2nd Col */}
                    {/* Start Of 3rd Col */}
                    <div className='col-md-3'>
                        <div className='row product-row'>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </Link>
                                
                            </div>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </Link>
                            </div>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </Link>
                            </div>
                            <div className='col-md-6 small-col'>
                                {/* <div class="card"> */}
                                <Link as={Link} to='/laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </Link>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    {/* End Of 3rd Col */}
                </div>
                {/* 2nd Row End */}
                {/* 3rd Row Start */}
                <div className='row mt-5'>
                    {/* Start Of 1st Col */}
                    <div className='col-md-3'>
                        <div className='row product-row'>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </Link>
                            </div>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='/laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* End Of 1st Col */}
                    {/* Start Of 2nd Col */}
                    <div className='col-md-1'></div>
                    <div className='col-md-3'>
                        <div className='row product-row'>
                            <div className='col-md-6 small-col'>
                                <a href='https://amzn.to/3Tem0cC' target="_blank">
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </a>
                            </div>
                            <div className='col-md-6 small-col'>
                                <a href='https://amzn.to/47VF3N5' target='_blank'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                    {/* End Of 2nd Col */}
                    {/* Start Of 3rd Col */}
                    <div className='col-md-3'>
                        <div className='row product-row'>
                            <div className='col-md-6 small-col'>
                                <Link as={Link} to='laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0CKLN7PSZ&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt="Lenovo V15" />
                                </Link>
                            </div>
                            <div className='col-md-6 small-col'>
                                {/* <div class="card"> */}
                                <Link as={Link} to='/laptop'>
                                    <img class="card-img-top" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00NXEINKE&Format=_SL110_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=samadhan980b-21&language=en_IN" alt='Apple MacBook Air' />
                                </Link>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    {/* End Of 3rd Col */}
                </div>
                {/* 3rd Row End */}
            </div>
        </>
    )
}
