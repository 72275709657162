import axios from 'axios';
import { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useCookies } from 'react-cookie';
import { Navigate, useNavigate, Link } from 'react-router-dom';
export default function ShowAllLaptop() {

    const [lapiData, setlapiData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        checkAdminLogin();
        getlapiData();
    }, []);

     // Function For Check Admin Is Loged In Or Not
     function checkAdminLogin() {
        const myCookieValue = readCookie('AdminId');

        if (!myCookieValue) {
            navigate('/login-admin')
        }
    }
    function getlapiData() {
        // Offline Database URL
        // axios.get('http://localhost:80/Sam_API/ShowallLaptops').then(function (response) {
        //     if (response.data == '') {
        //         alert('Laptop Currently Unavailable');
        //         // navigate('/laptop');               
        //     } else {
        //         setlapiData(response.data);
        //     }
        // });
        // ONLine Database URL
        axios.get('https://amolkhotevibe.000webhostapp.com/Sam_API/ShowallLaptops').then(function (response) {
            if (response.data == '') {
                alert('Laptop Currently Unavailable');
                navigate('/laptop');               
            } else {
                setlapiData(response.data);
            }
        });
    }
    const [cookies, setCookie, removeCookie] = useCookies();

    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };
       // For Logout Admin
  function logout() {
    const conf = window.confirm("Do You Want LogOut");
    if (conf) {
      const activeUserId = readCookie('AdminId');
      //  Offline Database Url
    //   axios.post('http://localhost:80/Sam_API/logout/' + activeUserId).then(res => {
    //     alert(res.data.message);
    //     removeCookie("AdminId");
    //     navigate('/login-admin');
    //   }).catch(err => console.log(err));
      //  ONLine Database Url
      axios.post('https://amolkhotevibe.000webhostapp.com/Sam_API/logout/' + activeUserId).then(res => {
        alert(res.data.message);
        removeCookie("AdminId");
        navigate('/login-admin');
      }).catch(err => console.log(err));
    }
  }
    return (
        <>
            <div className="container py-5 px-5">
                <div className='row'>
                    <div className='col-md-8'>
                        <Navbar expand="lg" >
                            <Navbar.Brand href="#" className='py-2 pl-2'><b>Admin Links</b></Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0"
                                    style={{ maxHeight: '100px' }}
                                    navbarScroll
                                >
                                </Nav>
                                <Nav>
                                    <Nav.Link as={Link} to="/new-admin" className="ms-3">New Admin</Nav.Link>
                                    <Nav.Link as={Link} to="/new-slider" className="ms-3">New Slider</Nav.Link>
                                    <Nav.Link as={Link} to="/add-trending-item" className='ms-3'>Add Trending Item</Nav.Link>
                                    <Nav.Link as={Link} to="/add-laptop-category" className="ms-3" >Laptop Category</Nav.Link>
                                    <Nav.Link as={Link} to="/add-new-laptop" className="ms-3">Add New Laptop</Nav.Link>
                                    <Nav.Link as={Link} to="/mobile" className="ms-3">Add New Mobile</Nav.Link>
                                    <Nav.Link as={Link} to="/mens-fashion" className="ms-3">Add New Men's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/womens-fashion" className="ms-3">Add New Women's Fashion</Nav.Link>
                                    <Nav.Link as={Link} to="/sound" className="ms-3">Add New Audio</Nav.Link>
                                    <Nav.Link className='ms-3'><button onClick={(e) => logout()} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>
                                        Delete
                                    </button></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>
            <div className='row'>
                <tr>
                    <th colSpan="2" className='text-center'><Link as='Link' to='/add-new-laptop' className='btn btn-outline-primary'>Add New Laptop</Link></th>
                </tr>
                <div className='col-md-12'>
                    <table className='table table-hover' cellSpacing="10">
                        <thead>
                            <tr>
                                <th colSpan="11" className='text-center'><h2>All Laptop Category Here</h2></th>
                            </tr>
                            <tr className='text-center'>
                                <th scope="col">Sr.No</th>
                                <th scope="col">Lapi Category Name</th>
                                <th scope="col">Laptop Image</th>
                                <th scope="col">Laptop Name</th>
                                <th scope="col">Price</th>
                                <th scope="col">MRP</th>
                                <th scope="col">Discount</th>
                                <th scope="col">Product Link</th>
                                <th scope="col">Active</th>
                                {/* <th scope="col">Updated At</th> */}
                                <th scope="col">Update Category</th>
                                <th scope="col">Delete Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lapiData.map((lapiData, key) =>
                                <tr key={key} className="text-center">
                                    {/* <td>{catData.id}</td> */}
                                    <td>{key + 1}</td>
                                    <td>{lapiData.cat_name}</td>
                                    <td><img src={lapiData.product_img_link} width="100px" height="100px" /></td>
                                    <td>{lapiData.laptop_name}</td>
                                    <td>{lapiData.price}</td>
                                    <td>{lapiData.MRP}</td>
                                    <td>{lapiData.discount}</td>
                                    <td> <textarea  name="cat_desc" rows="4" cols="50" value={lapiData.product_link} readonly></textarea> </td>
                                    <td>{lapiData.active == 1 ? <button className="btn btn-outline-primary">Active</button>
                                        : <button className="btn btn-outline-danger">In Active</button>}</td>
                                    {/* <td>{catData.active}</td> */}
                                    {/* <td>{catData.created_at}</td> */}
                                    {/* <td>{catData.updated_at}</td> */}
                                    {/* <td>{lapiData.created_at}</td> */}
                                    <td>
                                        <Link to={`/editlaptopcategory/${lapiData.id}`} className="btn btn-outline-success" style={{ marginRight: "10px" }}>Edit</Link>
                                    </td>
                                    <td>
                                        <button className="btn btn-outline-danger" style={{ marginRight: "10px" }}>Delete</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
       
        </>
    );
}