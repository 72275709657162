import { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useCookies } from 'react-cookie';
export default function ShowAllAdmin() {
    const navigate = useNavigate();
    const [AdminData, setAdminData] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies();
    useEffect(() => {
        checkAdminLogin();
        getAdminData();
    }, []);

    // Function For Check Admin Is Loged In Or Not
    function checkAdminLogin() {
        const myCookieValue = readCookie('AdminId');

        if (!myCookieValue) {
            navigate('/login-admin')
        }
    }
    //  End Of Getting Cookies


    function getAdminData() {
        // Offline Database URL
        // axios.get('http://localhost:80/Sam_API/ShowallAdmin').then(function (response) {
        //     if (response.data == '') {
        //         alert('Admin Not Found In Database');
        //         navigate('/new-admin');
        //     } else {
        //         setAdminData(response.data);
        //     }

        // });
        // Online Database URL
        axios.get('https://intrendingvibe.com/Sam_API/ShowallAdmin').then(function (response) {
            if (response.data == '') {
                alert('Admin Not Found In Database');
                navigate('/new-admin');
            } else {
                setAdminData(response.data);
            }

        });
    }

    // ************************************************************
    //          Getting Cookies Value
    const readCookie = (AdminId) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === AdminId) {
                return cookieValue;
            }
        }

        return null; // Cookie not found
    };

    // For Logout Admin
    function logout() {
        const conf = window.confirm("Do You Want LogOut");
        if (conf) {
            const activeUserId = readCookie('AdminId');
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/logout/' + activeUserId).then(res => {
            //     alert(res.data.message);
            //     removeCookie("AdminId");
            //     navigate('/login-admin');
            // }).catch(err => console.log(err));
            //  Online Database Url
            axios.post('https://intrendingvibe.com/Sam_API/logout/' + activeUserId).then(res => {
                alert(res.data.message);
                removeCookie("AdminId");
                navigate('/login-admin');
            }).catch(err => console.log(err));
        }
    }
    return (
        <div className="container py-5 px-5" >
            <div className='row'>
                <div className='col-md-8'>
                    <Navbar expand="lg" >
                        <Navbar.Brand href="#" className='py-2 pl-2'><b>Admin Links</b></Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                            </Nav>
                            <Nav>
                                <Nav.Link as={Link} to="/new-admin" className="ms-3">New Admin</Nav.Link>
                                <Nav.Link as={Link} to="/new-slider" className="ms-3">New Slider</Nav.Link>
                                <Nav.Link as={Link} to="/add-trending-item" className='ms-3'>Add Trending Item</Nav.Link>
                                <Nav.Link as={Link} to="/add-laptop-category" className="ms-3" >Laptop Category</Nav.Link>
                                <Nav.Link as={Link} to="/add-new-laptop" className="ms-3">Add New Laptop</Nav.Link>
                                <Nav.Link as={Link} to="/mobile" className="ms-3">Add New Mobile</Nav.Link>
                                <Nav.Link as={Link} to="/mens-fashion" className="ms-3">Add New Men's Fashion</Nav.Link>
                                <Nav.Link as={Link} to="/womens-fashion" className="ms-3">Add New Women's Fashion</Nav.Link>
                                <Nav.Link as={Link} to="/sound" className="ms-3">Add New Audio</Nav.Link>
                                <Nav.Link className='ms-3'><button onClick={(e) => logout()} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>
                                    Delete
                                </button></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
            <div className='row py-5'>
                <div className='col-md-1'></div>
                <div className='col-md-10 mt-5'>
                    <table className='table table-hover' cellSpacing="10">
                        <thead>
                            <tr>
                                <th colSpan="9" className='text-center'><h2>All Admin Here</h2></th>
                            </tr>
                            <tr>
                                <th colSpan="9"><Link as='Link' to='/new-admin' className='btn btn-outline-primary'>Add New Admin</Link></th>
                            </tr>

                            <tr className='text-center'>
                                <th scope="col">Sr.No</th>
                                <th scope="col">Admin Name</th>
                                <th scope="col">UserName</th>
                                <th scope="col">Email</th>
                                <th scope="col">Active Or Not Active</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Updated At</th>
                                <th scope="col">Update Admin</th>
                                <th scope="col">Delete Admin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AdminData.map((AdminData, key) =>
                                <tr key={key} className="text-center">
                                    {/* <td>{catData.id}</td> */}
                                    <td>{key + 1}</td>
                                    {/* <td><img src={imgData.img_url} width="100px" height="100px" /></td> */}
                                    <td>{AdminData.name}</td>
                                    <td>{AdminData.username}</td>
                                    <td>{AdminData.email}</td>
                                    <td>{AdminData.active == 1 ? <button onClick={e => inactiveAdmin(AdminData.id)} className="btn btn-outline-primary">Active</button>
                                        : <button onClick={e => activeAdmin(AdminData.id)} className="btn btn-outline-danger">In Active</button>}</td>

                                    <td>{AdminData.created_at}</td>
                                    <td>{AdminData.updated_at}</td>
                                    <td>
                                        <Link to={`/editadmin/${AdminData.id}`} className="btn btn-outline-success" style={{ marginRight: "10px" }}>Edit</Link>
                                    </td>
                                    <td>
                                        <button onClick={e => deleteSubmit(AdminData.id)} className="btn btn-outline-danger" style={{ marginRight: "10px" }}>Delete</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );

    // FUnction For Active Admin
    function activeAdmin(id) {
        const conf = window.confirm("Do You Want To Active This Admin");
        if (conf) {
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/activeAdmin/' + id).then(res => {
            //     alert(res.data.message);
            //     navigate(0);
            // }).catch(err => console.log(err));
            //  Online Database Url
            axios.post('https://intrendingvibe.com/Sam_API/activeAdmin/' + id).then(res => {
                alert(res.data.message);
                navigate(0);
            }).catch(err => console.log(err));
        }
    }
    // FUnction For Active Admin
    function inactiveAdmin(id) {
        const conf = window.confirm("Do You Want To In Active This Admin");
        if (conf) {
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/inactiveAdmin/' + id).then(res => {
            //     alert(res.data.message);
            //     navigate(0);
            // }).catch(err => console.log(err));
            //  Online Database Url
            axios.post('https://intrendingvibe.com/Sam_API/inactiveAdmin/' + id).then(res => {
                alert(res.data.message);
                navigate(0);
            }).catch(err => console.log(err));
        }
    }
    // For Delete Category
    function deleteSubmit(id) {
        const conf = window.confirm("Do You Want To Delete This Admin");
        if (conf) {
            //  Offline Database Url
            // axios.post('http://localhost:80/Sam_API/deleteadmin/' + id).then(res => {
            //     alert(res.data.message);
            //     navigate(0);
            // }).catch(err => console.log(err));
            //  ONline Database Url
            axios.post('https://intrendingvibe.com/Sam_API/deleteadmin/' + id).then(res => {
                alert(res.data.message);
                navigate(0);
            }).catch(err => console.log(err));
        }
    }
}