import { Component } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
export class PrivacyPolicy extends Component{
    render(){
        return(
            <>
            <h1 className="py-5 px-5">In privacy Policy Page</h1>
            </>
        )
    }
}